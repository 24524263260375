<template>
  <layout v-load="loading || loadDepartment">
    <div class="hours-av">
      <div class="hours-av__head">
        <div class="row">
          <div class="col-md-6">
            <h2>Hours of availability</h2>
          </div>
          <div class="col-md-6 pb-3">
            <div class="d-flex justify-content-end">
              <div class="search-input-multiselect">
                <multiselect :multiple="false" track-by="name" label="name" placeholder="Name" v-model="search"
                             :options="employeeList" :searchable="true">
                  <template slot="option" slot-scope="props">
                    <div class="option__desc" style="display: flex; justify-content: space-between;">
                      <span style="font-weight: 500;font-size: 13px; color: #2D3338;">{{
                          $shortText(props.option.name, 15)
                        }}</span>
                      <span style="font-weight: 400;font-size: 13px;color: #686D81;">{{
                          props.option.department.title
                        }}</span>
                    </div>
                  </template>
                </multiselect>
                <i>
                  <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M9.5 5.5C9.5 7.70914 7.70914 9.5 5.5 9.5C3.29086 9.5 1.5 7.70914 1.5 5.5C1.5 3.29086 3.29086 1.5 5.5 1.5C7.70914 1.5 9.5 3.29086 9.5 5.5ZM8.28962 10.2411C7.47176 10.7233 6.51819 11 5.5 11C2.46243 11 0 8.53757 0 5.5C0 2.46243 2.46243 0 5.5 0C8.53757 0 11 2.46243 11 5.5C11 7.00927 10.3921 8.37655 9.40774 9.37034C9.45115 9.39851 9.49227 9.43161 9.53033 9.46967L13.0303 12.9697C13.3232 13.2626 13.3232 13.7374 13.0303 14.0303C12.7374 14.3232 12.2626 14.3232 11.9697 14.0303L8.46967 10.5303C8.38542 10.4461 8.3254 10.3468 8.28962 10.2411Z"
                          fill="#BBBBBB"/>
                  </svg>
                </i>
                <div class="search-input-multiselect__close" v-if="search">
                  <i @click.prevent="search = null">
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M7 14C10.866 14 14 10.866 14 7C14 3.13401 10.866 0 7 0C3.13401 0 0 3.13401 0 7C0 10.866 3.13401 14 7 14ZM3.64645 3.64645C3.45118 3.84171 3.45118 4.15829 3.64645 4.35355L6.29289 7L3.64645 9.64645C3.45118 9.84171 3.45118 10.1583 3.64645 10.3536C3.84171 10.5488 4.15829 10.5488 4.35355 10.3536L7 7.70711L9.64645 10.3536C9.84171 10.5488 10.1583 10.5488 10.3536 10.3536C10.5488 10.1583 10.5488 9.84171 10.3536 9.64645L7.70711 7L10.3536 4.35355C10.5488 4.15829 10.5488 3.84171 10.3536 3.64645C10.1583 3.45118 9.84171 3.45118 9.64645 3.64645L7 6.29289L4.35355 3.64645C4.15829 3.45118 3.84171 3.45118 3.64645 3.64645Z"
                            fill="#DDDDDD"/>
                    </svg>
                  </i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="hours-av-department">
              <div class="hours-av-department__inner">
                <a href="#" :class="{'active': choosedDepartment === 150 }" @click.prevent="choosedDepartment = 150"
                   v-scroll-to="{
                  el: '#Favourite',
                  offset: -120
                }">
                  <i>
                    <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                          d="M9 0L11.0206 6.21885H17.5595L12.2694 10.0623L14.2901 16.2812L9 12.4377L3.70993 16.2812L5.73056 10.0623L0.440492 6.21885H6.97937L9 0Z"
                          fill="#4668DD"/>
                    </svg>
                  </i>
                </a>
                <a href="#" v-for="item in departments" :key="item.value"
                   @click.prevent="choosedDepartment = item.value" :class="{'active': choosedDepartment === item.value}"
                   v-scroll-to="{
                  el: `#${item.text}`,
                  offset: -120
                }">{{ item.text }}</a>
              </div>
              <div class="hours-av-department__range" ref="range">
                <div class="line-group" v-for="item in 24" :key="item">
                  <div class="line line-big"></div>
                  <div class="lines">
                    <div class="line line-small"></div>
                    <div class="line line-medium"></div>
                    <div class="line line-small"></div>
                  </div>
                </div>
                <RedTimeArrow
                    v-if="containerWidth > 0 && uzbTime"
                    :containerWidth="containerWidth"
                    :time="uzbTime"
                    :config="redArrowConfig"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="hours-av__body" ref="hours-av__body" id="hours-body">
        <div class="row">
          <div class="col-md-12">
            <div class="d-flex justify-content-end pt-3" style="position: absolute; right: 12px; top: 0; z-index: 13">
              <button
                  class="btn btn-primary"
                  :disabled="employeesFetching"
                  @click.prevent="$bvModal.show('add-favourites')"
              >
                <div class="d-flex align-items-center">
                  <b-spinner v-if="employeesFetching" small class="mr-2"/>
                  <span>Edit favourites</span>
                </div>
              </button>
            </div>
          </div>
        </div>
        <div class="hours-av__body-item" v-for="(item, idx) in employees" :key="idx">
          <div class="department" :id="`${item.department_name}`">
            <h5 style="margin-bottom: 0;">{{ item.department_name }}</h5>
          </div>
          <div class="employees" v-if="item.employees.length > 0">
            <div class="employees__item" v-for="(employee) in item.employees" :key="employee.uid">
              <div class="name">
                <p
                    :title="employee.name"
                    :id="`${employee.name.replace(/ /g,'')}`"
                    class="text-ellipsis cursor-pointer"
                >{{ employee.name }}</p>
                <i
                    v-if="checkIsOfficeByEmployee(employee)"
                    class='bx bx-buildings font-size-14 mr-1'
                ></i>
                <i
                    v-else
                    class='bx bx-home font-size-14 mr-1'
                ></i>
              </div>
              <div class="range">
                <div class="range-default"></div>
                <div class="range-vacation" v-if="getVacationStatus(employee, 3)">
                  <span>{{ getVacationDate(employee, 'Day-off') }}</span>
                </div>
                <div class="range-vacation maternity-vacation-color" v-else-if="getVacationStatus(employee, 5)">
                  <span>{{ getVacationDate(employee, 'Maternity leave') }}</span>
                </div>
                <div class="range-info" v-else>
                  <p v-if="employee?.vacationDays?.length">
                    {{ getVacationDate(employee, 'Day-off') }}
                  </p>
                </div>
                <div class="range-default"
                     :style="getStyleForRange({date_start:$moment(range.date_start).format('HH:mm'), date_end: $moment(range.date_end).format('HH:mm')})"
                     v-for="(range, idx) in employee?.attendance?.date_interval" :key="idx">
                  <div class="range-default range-green" style="background: #1DBA87; z-index: 12"></div>
                  <span class="range-green__border-left" style="z-index: 11"></span>
                  <span class="range-green__border-right" style="z-index: 11"></span>
                </div>
              </div>
            </div>
          </div>
          <p v-else style="color: #686D81; font-size: 12px; padding-bottom: 40px; margin-bottom: 0;">Employees not found
            today</p>
        </div>
        <div class="d-flex justify-content-end" style="height: 100%; width: 100%">
          <div class="ruler-space" ref="ruler-space">
            <div class="hours-grid" v-for="(n, idx) in 96" :key="idx" :data-minutes="idx" @mouseenter="addMinutes"></div>
          </div>
          <!--<div class="ruler" :style="getStyleForRuler"></div>-->
          <div class="ruler-hint" :style="getStyleForHint">
            <span>{{
                $moment(`${$moment().format('YYYY-MM-DD')} ${hint.time}`, 'YYYY-MM-DD HH:mm').add(timeLocaleStart, 'hours').format('HH:mm')
              }}</span>
            <span>{{ hint.time }}</span>
          </div>
        </div>
      </div>
    </div>
    <b-modal
        id="add-favourites"
        @hidden="updateIndex()"
        size="md"
        centered
        scrollable
        hide-footer
    >
      <div class="d-flex flex-column">
        <div class="search-default">
          <input type="text" placeholder="Name" v-model="searchInModal">
          <i>
            <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M9.5 5.5C9.5 7.70914 7.70914 9.5 5.5 9.5C3.29086 9.5 1.5 7.70914 1.5 5.5C1.5 3.29086 3.29086 1.5 5.5 1.5C7.70914 1.5 9.5 3.29086 9.5 5.5ZM8.28962 10.2411C7.47176 10.7233 6.51819 11 5.5 11C2.46243 11 0 8.53757 0 5.5C0 2.46243 2.46243 0 5.5 0C8.53757 0 11 2.46243 11 5.5C11 7.00927 10.3921 8.37655 9.40774 9.37034C9.45115 9.39851 9.49227 9.43161 9.53033 9.46967L13.0303 12.9697C13.3232 13.2626 13.3232 13.7374 13.0303 14.0303C12.7374 14.3232 12.2626 14.3232 11.9697 14.0303L8.46967 10.5303C8.38542 10.4461 8.3254 10.3468 8.28962 10.2411Z"
                    fill="#BBBBBB"/>
            </svg>
          </i>
        </div>
        <div class="employee">
          <div class="employee__item" v-for="(item, idx) in getEmployeesFromSearch" :key="idx">
            <img :src="item.photo" alt="" class="mr-3">
            <div class="d-flex flex-column" style="padding:0 30px 0 30px">
              <p>{{ $shortText(item.name, 25) }}</p>
              <span>{{ item.position }}</span>
            </div>
            <div class="star" @click.prevent="updateFavourite(item.uid)"
                 :class="{ 'star-active' : item.isFavourite, 'star-wait': item.isLoad }">
              <i>
                <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M9 0L11.0206 6.21885H17.5595L12.2694 10.0623L14.2901 16.2812L9 12.4377L3.70993 16.2812L5.73056 10.0623L0.440492 6.21885H6.97937L9 0Z"
                      fill="#BBBBBB"/>
                </svg>
              </i>
            </div>
          </div>
        </div>
      </div>
      <div class="close-block">
        <div class="d-flex justify-content-center w-100">
          <button class="btn btn-primary btn-small" style="height: 30px; width: 116px; padding: 0;"
                  @click.prevent="$bvModal.hide('add-favourites')">Close
          </button>
        </div>
      </div>
    </b-modal>
  </layout>
</template>

<script>
import Multiselect from 'vue-multiselect';
import RedTimeArrow from "@/components/profile/red-time-arrow.vue";
import TimeIntervalMixin from "@/mixins/time-interval-mixin";
import OfficeIcon from '@/assets/images/icons/ic-office.svg'
import HomeIcon from '@/assets/images/icons/ic-home.svg'

export default {
  name: "hours-availability",
  components: {
    Multiselect,
    RedTimeArrow
  },
  mixins: [TimeIntervalMixin],
  data() {
    return {
      choosedDepartment: 150, // favourite department
      rangeWidth: 0,
      search: null,
      searchInModal: '',
      localTime: this.$moment(),
      timeLocaleStart: null,
      ruler: {
        left: 0
      },
      hint: {
        time: 0,
        top: 0
      },
      departmentPositions: [],
      employeesFetching: false,
      containerWidth: null,
      redArrowConfig: {
        style: {
          bottom: '-20px'
        },
        excludePaddingPx: 0
      },
      OfficeIcon,
      HomeIcon
    }
  },
  methods: {
    getCordFromTime(time) {
      const timeArr = time.split(':')
      let h = timeArr[0]
      let m = timeArr[1]
      let result = (parseInt(h) * (100 / 24)) + ((parseInt(m) / 15) * ((100 / 24) / 4))
      return result
    },
    getVacationDate(employee, title) {
      const days = employee?.vacationDays ?? []
      return days.length > 1
          ? `${title} ${this.$moment(days[0]).format('DD.MM')} - ${this.$moment(days[days.length - 1]).format('DD.MM.YYYY')} inclusive`
          : `${title} ${this.$moment(days[0]).format('DD.MM.YYYY')}`
    },
    getVacationStatus(employee, typeId) {
      return employee?.attendance?.type_id === typeId
    },
    getStyleForRange({date_start, date_end}) {
      return {
        left: this.getCordFromTime(date_start) + '%',
        width: (this.getCordFromTime(date_end === '00:00' ? '23:59' : date_end) - this.getCordFromTime(date_start)) + '%'
      }
    },
    setCordForRuler(ev) {
      this.ruler.left = ev.clientX
      this.hint.top = ev.pageY
    },
    addMinutes: function (e) {
      this.hint.time = this.$moment('00:00', 'HH:mm').add(e.target.getAttribute('data-minutes') * 15, 'minutes').format('HH:mm');
    },
    async updateFavourite(uid) {
      this.$store.commit('hoursAvailability/UPDATE_WAIT_STATUS_EMPLOYEE', {id: uid, status: true})
      await this.$store.dispatch('hoursAvailability/updateFavouriteEmployee', this.$convertToFormData({
        employee_uid: uid,
        _method: 'POST'
      })).then(async (res) => {
        if (res) {
          this.$store.commit('hoursAvailability/UPDATE_STATUS_EMPLOYEE', uid)
          this.$store.commit('hoursAvailability/SET_FAVOURITE_EMPLOYEE', uid)
        }
      }).finally(() => {
        this.$store.commit('hoursAvailability/UPDATE_WAIT_STATUS_EMPLOYEE', {id: uid, status: false})
      })
    },
    updateRangeWidth() {
      this.containerWidth = this.$refs['range']?.offsetWidth ?? 0
      this.rangeWidth = this.$refs['range'].getBoundingClientRect().width
    },
    async updateIndex() {
      this.collectDepartmentPositions()
    },
    collectDepartmentPositions() {
      this.departmentPositions.push({
        id: 150,
        department_name: 'Favourite',
        position: document.getElementById(`Favourite`)?.getBoundingClientRect().top
      })
      this.departments.forEach(item => {
        this.departmentPositions.push({
          id: item.value,
          department_name: item.text,
          position: document.getElementById(`${item.text}`)?.getBoundingClientRect().top
        })
      })
    },
    setActiveDepartmentTab() {
      let find = document.elementFromPoint(window.innerWidth >= 991 ? 274 : 0, 145).getElementsByTagName('h5') // cord for scroll
      if (find.length > 0 && this.departmentPositions.length > 0) {
        const findDepartment = this.departmentPositions.find(item => item.department_name === find[0].textContent)
        if (findDepartment) {
          this.choosedDepartment = findDepartment.id
        }
      }
    },
    checkIsOfficeByEmployee(employee) {
      if (employee.attendance) {
        return employee.attendance?.location_id === 1
      }
      return !employee.remotely
    }
  },
  async mounted() {
    let [elHtml] = document.getElementsByTagName('html')
    elHtml.classList.add('hide-scroll');
    this.timeLocaleStart = this.$moment(this.localTime.format('YYYY-MM-DD HH:mm:ss')).diff(this.$moment(this.uzbTime).format('YYYY-MM-DD HH:mm:ss'), 'hours')
    await this.$store.dispatch('departments/getDepartments')
    await this.$store.dispatch('hoursAvailability/getAllDateIntervals').then(async (res) => {
      if (res) {
        this.rangeWidth = this.$refs['range'].getBoundingClientRect().width
        this.$refs['ruler-space'].addEventListener('mousemove', this.setCordForRuler)
        this.collectDepartmentPositions()
        window.addEventListener('scroll', this.setActiveDepartmentTab)
        window.addEventListener('resize', this.updateRangeWidth)
        this.employeesFetching = true;
        await this.$store.dispatch('hoursAvailability/getAllEmployees');
        this.employeesFetching = false;
        this.containerWidth = this.$refs['range']?.offsetWidth ?? 0
        this.uzbTime = this.$moment().utc().add(5, 'hours').format('YYYY-MM-DD HH:mm:ss')
        this.setIntervalForTimeline(60)
      }
    })
  },
  beforeDestroy() {
    this.$refs['ruler-space'].removeEventListener('mousemove', this.setCordForRuler)
    window.removeEventListener('scroll', this.setActiveDepartmentTab)
    window.removeEventListener('resize', this.updateRangeWidth)
  },
  destroyed() {
    let [elHtml] = document.getElementsByTagName('html')
    elHtml.classList.remove('hide-scroll');
  },
  computed: {
    employeeList() {
      return this.$store.getters['hoursAvailability/employeeList']
    },
    loading() {
      return this.$store.state.hoursAvailability.loading
    },
    loadDepartment() {
      return this.$store.state.departments.loading
    },
    employees() {
      return this.$store.state.hoursAvailability.departments
    },
    departments() {
      return this.$store.state.departments.list.map((department) => ({
        text: department.title, value: department.id
      }));
    },
    getStyleForRuler() {
      let leftOffset = window.innerWidth <= 992 ? 24 : 274
      return {
        left: (this.ruler.left - leftOffset) + 'px'
      }
    },
    getStyleForHint() {
      let leftOffset = window.innerWidth <= 992 ? 44 : 294
      let topOffset = window.innerWidth <= 992 ? 120 : 75
      return {
        left: (this.ruler.left - leftOffset) + 'px',
        top: (this.hint.top - topOffset) + 'px'
      }
    },
    getEmployeesFromSearch() {
      return this.employeeList.filter(item => item.name.toLowerCase().includes(this.searchInModal.toLowerCase()))
    }
  },
  watch: {
    'search'(val) {
      if (val) {
        this.$scrollTo(`#${val.name.replace(/ /g, '')}`, 500, {
          offset: -130
        })
      } else {
        this.$scrollTo(`#hours-body`, 500, {
          offset: -130
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
.ruler-space{
  display: flex;
  .hours-grid{
    flex: 1 1 0px;
  }
  .hours-grid:hover{
    border-left: 1px solid #333;
  }
}
</style>
